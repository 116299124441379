import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiStore } from '../../../../core/store/ui.store';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { SideFormComponent } from '../../forms/side-form/side-form.component';

@Component({
  selector: 'app-side-form-modal',
  standalone: true,
  imports: [
    SavingButtonComponent,
    CancelButtonComponent,
    TranslateModule,
    SideFormComponent,
  ],
  templateUrl: './side-form-modal.component.html',
  styleUrl: './side-form-modal.component.scss',
})
export class SideFormModalComponent {
  @Input() isSideFormOpen: boolean = false;

  constructor(public uiStore: UiStore) {}
}
